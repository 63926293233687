// sizes
//-----------------------------------------------------------
$size: 8px;

// breakpoints
//-----------------------------------------------------------
$max-575:  575px;
$min-576:  576px;
$max-767:  767px;
$min-768:  768px;
$max-991:  991px;
$min-992:  992px;
$max-1199: 1199px;
$min-1200: 1200px;
$max-1399: 1399px;
$min-1400: 1400px;

// colors
//-----------------------------------------------------------
$white:          #ffffff;
$black:          #262626;
$blue:           #005cee;
$gray:           #e1e3e6;
$gray-dark:      #dbdbdb;
$gray-light:     #f5f5f5;
$gray-dark-ultra:#717e95;
$form-error:     #f0506e;
$form-disabled:  #dadada;


// others
//-----------------------------------------------------------
$border-radius: 8px;