/* .l-body
-----------------------------------------------------------*/
$r: ".l-body";

// block
//-----------------------------------------------------------
#{$r} {
  color: $black;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  font-family: "Inter", sans-serif;

  @media (min-width: $min-1200) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 375px;
    height: 667px;
    position: absolute;
    transform: translateZ(0);

    &:after {
      left: 0;
      right: 0;
      bottom: -60px;
      content: "";
      width: 40px;
      height: 40px;
      margin: auto;
      position: absolute;
      border-radius: 100%;
      border: 2px solid #4a4b4d;
    }

    &:before {
      top: -60px;
      left: -14px;
      right: -14px;
      bottom: -80px;
      content: "";
      position: absolute;
      background: #1d1e20;
      border-radius: 30px;
      -webkit-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
      box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
    }
  }

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
