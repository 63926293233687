/* .c-text
-----------------------------------------------------------*/
$r: ".c-text";

// block
//-----------------------------------------------------------
#{$r} {
  margin-left: auto;
  margin-right: auto;
  max-width: $size * 38;
  margin-top: $size * 2;

  &:first-of-type {
    margin-top: 0;
  }

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
  &--gray {
    color: $gray-dark-ultra;
  }
}

// influence
//-----------------------------------------------------------
