/* .c-title
-----------------------------------------------------------*/
$r: ".c-title";

// block
//-----------------------------------------------------------
#{$r} {
  font-size: 20px;
  font-weight: 600;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
  &--fs-14 {
    font-size: 14px;
  }
}

// influence
//-----------------------------------------------------------
