/* .c-get
-----------------------------------------------------------*/
$r: ".c-get";

// block
//-----------------------------------------------------------
#{$r} {
  padding: $size * 4 $size * 2;
  background-color: $gray-light;
  border-radius: $border-radius;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
