/* .c-close
-----------------------------------------------------------*/
$r: ".c-close";

// block
//-----------------------------------------------------------
#{$r} {
  top: $size * 2;
  right: $size * 2;
  font-size: 16px;
  cursor: pointer;
  position: absolute;

  // element
  //---------------------------------------------------------
  &__inner {
    display: grid;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
