/* .c-copy
-----------------------------------------------------------*/
$r: ".c-copy";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  flex-direction: column;
  padding: $size * 2;
  border:  1px solid $gray-dark;
  border-radius: $border-radius;

  // element
  //---------------------------------------------------------
  > * {
    margin-top: $size * 2;

    &:first-child {
      margin-top: 0;
    }
  }

  &__type {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
