/* .c-line
-----------------------------------------------------------*/
$r: ".c-line";

// block
//-----------------------------------------------------------
#{$r} {
  width: 100%;
  height: 1px;
  background-color: $gray-dark;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
