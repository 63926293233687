/* .c-link
-----------------------------------------------------------*/
$r: ".c-link";

// block
//-----------------------------------------------------------
#{$r} {
  color: $black;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
  &--blue {
    color: $blue;
  }
}

// influence
//-----------------------------------------------------------
