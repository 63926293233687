/* .l-row
-----------------------------------------------------------*/
$r: ".l-row";

// block
//-----------------------------------------------------------
#{$r} {
  margin-top: $size * 4;

  &:first-of-type {
    margin-top: 0;
  }

  // element
  //---------------------------------------------------------
  .icon {

    &--error {
      font-size: 48px;
    }

    &--check {
      font-size: 48px;
      width: calc(135.1em / 100);
    }

    &--envelope {
      font-size: 48px;
      width: calc(113em / 100);
    }
  }

  // modifier
  //---------------------------------------------------------
  &--mt-16 {
    margin-top: $size * 2;
  }

  &--mt-80 {
    margin-top: $size * 10;
  }
}

// influence
//-----------------------------------------------------------
