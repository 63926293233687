/* .c-card
-----------------------------------------------------------*/
$r: ".c-card";

// block
//-----------------------------------------------------------
#{$r} {
  display: grid;
  align-items: center;
  grid-gap: $size * 2;
  width: 100%;
  margin: 0 auto;
  padding: $size * 2;
  max-width: $size * 44;
  min-height: $size * 22;
  border-radius: $border-radius;
  // background-color: $gray-dark;
  background-image: linear-gradient(90deg, rgba(234,114,255,1) 0%, rgba(255,0,126,1) 50%, rgba(255,170,108,1) 100%);

  // element
  //---------------------------------------------------------
  &__name {
    color: $white;
    height: $size * 5;
    padding: 0 $size * 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: $size * 5;
    border-radius: $border-radius;
    background-color: rgba(64, 77, 97, 0.25);
  }

  &__number {
    color: $white;
    font-size: 24px;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
