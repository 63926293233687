/* .c-input
-----------------------------------------------------------*/
$r: ".c-input";

// block
//-----------------------------------------------------------
#{$r} {
  display: grid;
  position: relative;

  // element
  //---------------------------------------------------------
  &__label {
    display: grid;
    position: relative;
  }

  &__type {
    width: 100%;
    padding: 0 $size * 2;
    min-height: $size * 6;
    border: 1px solid $gray;
    background-color: $white;
    border-radius: $border-radius;
    transition: 0.15s;

    [contenteditable] {
      cursor: text;
      padding-top: $size;
      padding-bottom: $size;
    }

    &:focus ~ #{$r}__title,
    &:not(:placeholder-shown) ~ #{$r}__title {
      left: $size;
      line-height: inherit;
      background-color: $white;
      transform: scale(0.84) translateY(-50%);
    }
  }

  &__title {
    top: 0;
    left: $size;
    cursor: text;
    // overflow: hidden;
    width: max-content;
    max-width: calc(100% - #{$size} * 2);
    position: absolute;
    white-space: nowrap;
    padding-left: $size;
    padding-right: $size;
    line-height: $size * 6;
    // text-overflow: ellipsis;
    transform-origin: top left;
    transition: transform 0.15s, background-color 0.15s;
  }

  &__icon {
    display: grid;
    place-items: center;
    top: 0;
    width: $size * 6;
    height: $size * 6;
    color: darken($gray, 8%);
    font-size: 16px;
    position: absolute;
    transition: color 0.3s;

    &--left {
      left: 0;
      pointer-events: none;

      ~ #{$r}__type,
      ~ [contenteditable] {
        padding-left: $size * 6;
      }

      ~ #{$r}__title {
        left: $size * 4;
        max-width: calc(100% - #{$size} * 5);
      }

      ~ #{$r}__icon--right ~ #{$r}__title {
        max-width: calc(100% - #{$size} * 8);
      }
    }

    &--right {
      right: 0;

      ~ #{$r}__type,
      ~ [contenteditable] {
        padding-right: $size * 6;
      }

      ~ #{$r}__title {
        right: $size * 4;
        max-width: calc(100% - #{$size} * 5);
      }
    }
  }

  &__message {
    display: none;
    text-align: left;
    transform: scale(0.84);
    transform-origin: top left;
  }

  // modifier
  //---------------------------------------------------------
  &--validation {

     #{$r}__message {
      order: 1;
      display: block;
      margin-top: $size;

      &--error {
        color: $form-error;

        ~  #{$r}__label {

          #{$r}__type {
            border-color: $form-error;
            box-shadow: 0 0 0 1px $form-error;
          }

          #{$r}__title {
            color: $form-error;
          }
        }
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    #{$r}__label {
      pointer-events: none;
      color: $form-disabled;
      border-color: $form-disabled;
    }

    #{$r}__icon {
      color: $form-disabled;
    }
  }

  &--readonly {
    pointer-events: none;
  }
}

// influence
//-----------------------------------------------------------