/* .l-page
-----------------------------------------------------------*/
$r: ".l-page";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  text-align: center;
  max-width: $size * 128;
  padding-top: $size * 6;
  padding-left: $size * 2;
  padding-right: $size * 2;
  padding-bottom: $size * 4;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
