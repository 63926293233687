/* .l-main
-----------------------------------------------------------*/
$r: ".l-main";

// block
//-----------------------------------------------------------
#{$r} {
  flex-grow: 1;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
