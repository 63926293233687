/* .c-select
-----------------------------------------------------------*/
$r: ".c-select";

// block
//-----------------------------------------------------------
#{$r} {
  width: 100%;
  color: $white;
  line-height: 1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: $size * 6;
  padding: 0 $size * 3;
  user-select: none;
  position: relative;
  border-radius: $border-radius;
  background-color: $black;
  text-align: center;

  // element
  //---------------------------------------------------------
  &__inner {
    display: grid;
    place-items: center;
  }

  // modifier
  //---------------------------------------------------------
  &:disabled {
    opacity: 0.3;
  }

  &--has-loader {
    cursor: wait;

    #{$r}__inner {
      opacity: 0;
    }
  }
}

// influence
//-----------------------------------------------------------
