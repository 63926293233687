/* .l-footer
-----------------------------------------------------------*/
$r: ".l-footer";

// block
//-----------------------------------------------------------
#{$r} {
  margin-top: $size * 4;

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
