// Inter
//-----------------------------------------------------------
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Inter";
  src: url("../fonts/Inter/Inter__400.woff") format("woff");
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Inter";
  src: url("../fonts/Inter/Inter__500.woff") format("woff");
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: "Inter";
  src: url("../fonts/Inter/Inter__600.woff") format("woff");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Inter";
  src: url("../fonts/Inter/Inter__700.woff") format("woff");
}