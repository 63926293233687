/* .c-button
-----------------------------------------------------------*/
$r: ".c-download-buttons";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  // element
  //---------------------------------------------------------

  .c-button{
    order: 1;
  }

  .c-link{
    order: 2;
    margin-top: $size * 2;
  }

  // modifier
  //---------------------------------------------------------

}

// influence
//-----------------------------------------------------------