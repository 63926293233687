/* .l-app
-----------------------------------------------------------*/
$r: ".l-app";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: $white;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
