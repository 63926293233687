/* .c-checkbox
-----------------------------------------------------------*/
@use 'sass:math';

$r: ".c-checkbox";

// block
//-----------------------------------------------------------
#{$r} {

  // element
  //---------------------------------------------------------
  &__label {
    display: grid;
    grid-gap: $size;
    grid-template-columns: max-content 1fr;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &__title {
    text-align: left;
    line-height: $size * 3;
  }

  &__icon {
    display: grid;
    place-items: center;
    height: $size * 3;
    min-width: $size * 3;
    max-width: $size * 3;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: math.div($border-radius, 2);
    transition: border-color 0.3s, background-color 0.3s;

    &:before {
      content: "";
      left: 4%;
      width: 64%;
      height: 32%;
      position: relative;
      border-left: 2px solid rgba(0, 0, 0, 0);
      border-bottom: 2px solid rgba(0, 0, 0, 0);
      transition: border-color 0.3s;
      transform: rotate(-45deg) translateY(-20%);
    }
  }

  &__link {
    color: $black;
    text-decoration: underline;
  }

  :checked {
    ~ #{$r}__icon {
      border-color: $black;
      background-color: $black;

      &:before {
        border-color: $white;
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------