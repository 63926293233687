/* reset
-------------------------------------------------------------*/
*,
::after,
::before {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

html {
  overflow: hidden;
  text-size-adjust: none;
}

body {
  margin: 0;
}

img {
  flex: none;
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
}

svg {
  width: 1em;
  height: 1em;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  user-select: none;
  fill: currentColor;
  pointer-events: none;
  display: inline-flex;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[href^="tel"] {
  @media (min-width: 768px) {
    pointer-events: none;
  }
}

input,
select,
button,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  color: inherit;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  vertical-align: bottom;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}