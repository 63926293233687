/* .c-code
-----------------------------------------------------------*/
$r: ".c-code";

// block
//-----------------------------------------------------------
#{$r} {
  display: grid;
  grid-gap: $size;
  grid-auto-flow: column;
  justify-content: center;

  // element
  //---------------------------------------------------------
  &__input {
    width: $size * 6;
    height: $size * 6;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border: 1px solid $gray;
    border-radius: $border-radius;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
